export default {
    accountMenu: {
        sessions: 'Próximas y recientes sesiones',
        notifications: 'Notificación',
        support: 'Ayuda y asistencia',
        information: 'Información jurídica',
        settings: 'Ajustes del perfil',
        messages: 'Mensajes',
        'work-with-us': 'Trabaja con nosotros'
    },
    menu: {
        'bb-client': 'Empieza a crecer con BB',
        'bb-expert': 'Conviértete en un experto en BB',
        blog: 'Blog y noticias'
    },
    registration: 'Registro',
    enter: 'Entrar',
    account: 'Mi cuenta',
    logout: 'Cerrar sesión',
    deleteAcc: 'Eliminar cuenta',
    footer: {
        faq: 'Preguntas frecuentes',
        policy: 'Política de privacidad'
    },
    session: {
        upcoming: 'Próximas sesiones',
        requested: 'Sesiones solicitadas',
        recent: 'Sesiones recientes'
    },
    photoDesc: 'Añade una foto real, ya que la cara de una persona siempre es más creíble.',
    dayStart: 'Inicio del día',
    topic: 'Tema',
    name: 'Nombre',
    surname: 'Apellido',
    password: 'Contraseña',
    birthday: 'Fecha de nacimiento',
    oldPass: 'Contraseña antigua',
    newPass: 'Nueva contraseña',
    confirmPass: 'Confirmar contraseña',
    becomeExpert: '',
    insertInfo: 'Introduce tu información personal para comenzar tu viaje como experto en BBuddy',
    changeUserData: 'Tus datos pueden añadirse o modificarse en cualquier momento',
    price: 'Precio',
    duration: 'Duración',
    search: 'Buscar',
    searchExpert: 'Buscar un experto',
    sort: 'Ordenar',
    sortPriceAsc: 'Por precio ascendente',
    sortPriceDesc: 'Por precio descendiente',
    details: 'Detalles',
    sessionLang: 'Idioma de la sesión',
    fromTo: 'de $ a $',
    apply: 'Solicitar',
    save: 'Guardar',
    changePass: 'Cambiar contraseña',
    getStarted: 'Empieza',
    delete: 'Eliminar',
    today: 'Hoy día'
}
