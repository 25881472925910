'use client'

import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

export const CustomSelect = (props: any) => {
    const { label, value, ...other } = props;
    const [isActiveLabel, setIsActiveLabel] = useState<boolean>(false);

    useEffect(() => {
        if (label) {
            setIsActiveLabel(!!value);
        } else {
            setIsActiveLabel(false);
        }
    }, [value]);

    return (
        <div className={`b-select-wrap ${isActiveLabel ? 'b-select__active' : ''}`}>
            <div className="b-select-label">{label}</div>
            <Select
                className="b-select"
                value={value}
                onFocus={!isActiveLabel ? () => setIsActiveLabel(true) : undefined}
                onBlur={() => setIsActiveLabel(!!value)}
                {...other}
            />
        </div>
    );
};
