export default {
    accountMenu: {
        sessions: 'Предстоящие и недавние сессии',
        notifications: 'Уведомления',
        support: 'Служба поддержки',
        information: 'Юридическая информация',
        settings: 'Настройки профиля',
        messages: 'Сообщения',
        'work-with-us': 'Сотрудничество'
    },
    menu: {
        'bb-client': 'Начните свой рост с BB',
        'bb-expert': 'Станьте экспертом BB',
        blog: 'Блог и новости'
    },
    registration: 'Регистрация',
    enter: 'Войти',
    account: 'Учетная запись',
    logout: 'Выйти',
    deleteAcc: 'Удалить учетную запись',
    footer: {
        faq: 'Частые вопросы',
        policy: 'Политика конфиденциальности'
    },
    session: {
        upcoming: 'Предстоящие сессии',
        requested: 'Запрошенные сессии',
        recent: 'Недавние сессии'
    },
    photoDesc: 'Добавьте реальную фотографию, ведь лицо человека всегда вызывает больше доверия.',
    dayStart: 'День начала',
    topic: 'Тема',
    name: 'Имя',
    surname: 'Фамилия',
    password: 'Пароль',
    birthday: 'Дата рождения',
    oldPass: 'Старый пароль',
    newPass: 'Новый пароль',
    confirmPass: 'Подтвердите пароль',
    becomeExpert: '',
    insertInfo: 'Введите личные данные и начните свой путь эксперта BBuddy',
    changeUserData: 'Добавить и изменить информацию о себе можно в любое время',
    price: 'Цена',
    duration: 'Длительность',
    search: 'Поиск',
    searchExpert: 'Поиск эксперта',
    sort: 'Сортировать',
    sortPriceAsc: 'Цена по возрастанию',
    sortPriceDesc: 'Цена по убыванию',
    details: 'Информация',
    sessionLang: 'Язык сессии',
    fromTo: 'от $ до $',
    apply: 'Применить',
    save: 'Сохранить',
    changePass: 'Изменить пароль',
    getStarted: 'Начать работу',
    delete: 'Удалить',
    today: 'Сегодня'
}
