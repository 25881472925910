export default {
    accountMenu: {
        sessions: 'Kommende & letzte Sitzungen',
        notifications: 'Benachrichtigung',
        support: 'Hilfe & Support',
        information: 'Rechtliche Informationen',
        settings: 'Profileinstellungen',
        messages: 'Nachrichten',
        'work-with-us': 'Arbeite mit uns'
    },
    menu: {
        'bb-client': 'Mit BB wachsen',
        'bb-expert': 'Werde BB-Experte',
        blog: 'Blog&News'
    },
    registration: 'Registrieren',
    enter: 'Anmelden',
    account: 'Mein Konto',
    logout: 'Abmelden',
    deleteAcc: 'Konto löschen',
    footer: {
        faq: 'FAQ',
        policy: 'Datenschutzrichtlinie'
    },
    session: {
        upcoming: 'Kommende Sitzungen',
        requested: 'Angefragte Sitzungen',
        recent: 'Letzte Sitzungen'
    },
    photoDesc: 'Füge ein echtes Foto hinzu, mit Gesicht wirkt es immer glaubwürdiger.',
    dayStart: 'Tagesbeginn',
    topic: 'Thema',
    name: 'Name',
    surname: 'Nachname',
    password: 'Passwort',
    birthday: 'Geburtsdatum',
    oldPass: 'Altes Passwort',
    newPass: 'Neues Passwort',
    confirmPass: 'Passwort bestätigen',
    becomeExpert: '',
    insertInfo: 'Füge deine persönlichen Informationen ein, um deine Reise als BBuddy-Experte zu beginnen',
    changeUserData: 'Du kannst deine Angaben jederzeit ergänzen oder ändern\n',
    price: 'Preis',
    duration: 'Dauer',
    search: 'Suche',
    searchExpert: 'Nach einem Experten suchen',
    sort: 'Sortieren',
    sortPriceAsc: 'Nach Preis aufsteigend',
    sortPriceDesc: 'Nach Preis absteigend',
    details: 'Details',
    sessionLang: 'Sitzungssprache',
    fromTo: 'von $ bis $',
    apply: 'Anwenden',
    save: 'Speichern',
    changePass: 'Passwort ändern',
    getStarted: 'Loslegen',
    delete: 'Löschen',
    today: 'Heute'
}
