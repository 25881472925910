export default {
    accountMenu: {
        sessions: 'Upcoming & Recent Sessions',
        notifications: 'Notification',
        support: 'Help & Support',
        information: 'Legal Information',
        settings: 'Profile Settings',
        messages: 'Messages',
        'work-with-us': 'Work With Us'
    },
    menu: {
        'bb-client': 'Start grow with BB',
        'bb-expert': 'Become BB Expert',
        blog: 'Blog&News'
    },
    registration: 'Registration',
    enter: 'Enter',
    account: 'My Account',
    logout: 'Log out',
    deleteAcc: 'Delete account',
    footer: {
        faq: 'FAQ',
        policy: 'Privacy Policy'
    },
    session: {
        upcoming: 'Upcoming Sessions',
        requested: 'Sessions Requested',
        recent: 'Recent Sessions'
    },
    photoDesc: 'Add a real photo, as a person\'s face is always more credible.',
    dayStart: 'Day start',
    topic: 'Topic',
    name: 'Name',
    surname: 'Surname',
    password: 'Password',
    birthday: 'Date of Birth',
    oldPass: 'Old Password',
    newPass: 'New Password',
    confirmPass: 'Confirm Password',
    becomeExpert: '',
    insertInfo: 'Insert your personal information to start your journey as a BBuddy Expert',
    changeUserData: 'Your info can either be added or amended at anytime',
    price: 'Price',
    duration: 'Duration',
    search: 'Search',
    searchExpert: 'Search for an Expert',
    sort: 'Sort',
    sortPriceAsc: 'By price ascending',
    sortPriceDesc: 'By price descending',
    details: 'Details',
    sessionLang: 'Session Language',
    fromTo: 'from $ to $',
    apply: 'Apply',
    save: 'Save',
    changePass: 'Change password',
    getStarted: 'Get started',
    delete: 'Delete',
    today: 'Today'
}
