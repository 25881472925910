export default {
    accountMenu: {
        sessions: 'Sessions futures et récentes',
        notifications: 'Notification',
        support: 'Aide et support',
        information: 'Informations légales',
        settings: 'Paramètres du profil',
        messages: 'Messages',
        'work-with-us': 'Travaillez avec nous'
    },
    menu: {
        'bb-client': 'Commencez à vous développer avec BB',
        'bb-expert': 'Devenez Expert BB',
        blog: 'Blog et actus'
    },
    registration: 'Inscription',
    enter: 'Saisir',
    account: 'Mon compte',
    logout: 'Déconnexion',
    deleteAcc: 'Supprimer le compte',
    footer: {
        faq: 'FAQ',
        policy: 'Politique de confidentialité'
    },
    session: {
        upcoming: 'Prochaines sessions',
        requested: 'Sessions demandées',
        recent: 'Sessions récentes'
    },
    photoDesc: 'Ajoutez une photo réelle, le visage d\'une personne est toujours plus crédible.',
    dayStart: 'Début de la journée',
    topic: 'Sujet',
    name: 'Prénom',
    surname: 'Nom de famille',
    password: 'Passe',
    birthday: 'Date de naissance',
    oldPass: 'Ancien mot de passe',
    newPass: 'Nouveau mot de passe',
    confirmPass: 'Confirmer le mot de passe',
    becomeExpert: '',
    insertInfo: 'Insérez vos informations personnelles pour commencer votre voyage en tant qu\'expert BBuddy',
    changeUserData: 'Vos informations peuvent être ajoutées ou modifiées à tout moment',
    price: 'Prix',
    duration: 'Durée',
    search: 'Recherche',
    searchExpert: 'Rechercher un expert',
    sort: 'Trier',
    sortPriceAsc: 'Par prix croissant',
    sortPriceDesc: 'Par prix décroissant',
    details: 'Détails',
    sessionLang: 'Langue de la session',
    fromTo: 'de $ à $',
    apply: 'Appliquer',
    save: 'Sauvegarder',
    changePass: 'Modifier le mot de passe',
    getStarted: 'Commencer',
    delete: 'Supprimer',
    today: 'Ce jour'
}
