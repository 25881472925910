import { apiClient } from '../lib/apiClient';
import { GeneralFilter, ExpertsData, ExpertDetails } from '../types/experts';

export const getExpertsList = async (locale: string, filter?: GeneralFilter) => {
    const response = await apiClient.post(
        '/home/coachsearch1',
    { ...filter },
        {
            headers: {
                'X-User-Language': locale
            }
        }
    );

    return response.data as ExpertsData || null;
};

export const getExpertById = async (id: string, locale: string) => {
    const response = await apiClient.post(
        '/home/coachdetails',
        { id },
        {
            headers: {
                'X-User-Language': locale
            }
        }
    );

    return response.data as ExpertDetails || null;
};
