export default {
    accountMenu: {
        sessions: 'Prossime e recenti sessioni',
        notifications: 'Notifica',
        support: 'Assistenza e supporto',
        information: 'Informazioni legali',
        settings: 'Impostazioni profilo',
        messages: 'Messaggi',
        'work-with-us': 'Lavora con noi'
    },
    menu: {
        'bb-client': 'Inizia a crescere con BB',
        'bb-expert': 'Diventa esperto BB',
        blog: 'Blog&Notizie'
    },
    registration: 'Registrazione',
    enter: 'Inserisci',
    account: 'Il mio account',
    logout: 'Disconnetti',
    deleteAcc: 'Elimina account',
    footer: {
        faq: 'Domande frequenti',
        policy: 'Informativa sulla privacy'
    },
    session: {
        upcoming: 'Prossime sessioni',
        requested: 'Sessioni richieste',
        recent: 'Sessioni recenti'
    },
    photoDesc: 'Aggiungi una foto vera: il volto di una persona è sempre più credibile.',
    dayStart: 'Inizio del giorno',
    topic: 'Argomento',
    name: 'Nome',
    surname: 'Cognome',
    password: 'Password',
    birthday: 'Data di nascita',
    oldPass: 'Vecchia password',
    newPass: 'Nuova password',
    confirmPass: 'Conferma password',
    becomeExpert: '',
    insertInfo: 'Inserisci i tuoi dati personali per iniziare il tuo viaggio come esperto BBuddy',
    changeUserData: 'I tuoi dati possono essere aggiunti o modificati in qualsiasi momento',
    price: 'Prezzo',
    duration: 'Durata',
    search: 'Ricerca',
    searchExpert: 'Cerca un Esperto',
    sort: 'Ordina',
    sortPriceAsc: 'Per prezzo crescente',
    sortPriceDesc: 'Per prezzo decrescente',
    details: 'Dettagli',
    sessionLang: 'Lingua sessione',
    fromTo: 'da $ a $',
    apply: 'Applica',
    save: 'Salva',
    changePass: 'Cambia password',
    getStarted: 'Inizia',
    delete: 'Elimina',
    today: 'Oggi'
}
